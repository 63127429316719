var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"white-background main-create-page",attrs:{"fluid":""}},[_c('div',{staticClass:"p-5 pb-0"},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"6","md":"6"}},[_c('h1',{staticClass:"form-title d-flex",staticStyle:{"font-size":"2rem"}},[(_vm.role_id > 0)?[_vm._v("Update Role")]:[_vm._v("Create a new Role")]],2)]),_c('v-col',{staticClass:"pt-0 text-right",attrs:{"cols":"6","md":"6"}},[_c('v-btn',{attrs:{"disabled":_vm.formLoading || _vm.pageLoading,"depressed":"","tile":""},on:{"click":function($event){return _vm.$router.push({ name: 'setting', params: { id: 1 }, query: { ..._vm.$route.query, clone: null } })}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"mx-2 custom-bold-button white--text",attrs:{"disabled":_vm.formLoading || _vm.pageLoading,"loading":_vm.formLoading,"tile":"","depressed":"","color":"blue darken-4 white--text"},on:{"click":_vm.onSubmit}},[_vm._v(" Save ")])],1)],1)],1),_c('v-form',{ref:"roleForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"formValid"}},[_c('perfect-scrollbar',{staticClass:"scroll custom-box-top-inner-shadow",staticStyle:{"height":"calc(100vh - 150px)","position":"relative"},attrs:{"options":{ suppressScrollX: true }}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"10","lg":"6"}},[_c('v-container',{staticClass:"py-0",attrs:{"fluid":""}},[_c('TextInput',{class:{
									required: !_vm.updateRole.role_name,
								},attrs:{"hide-details":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"id":"name","rules":[
									_vm.vrules.required(_vm.updateRole.role_name, 'Role Name'),
									_vm.vrules.minLength(_vm.updateRole.role_name, 'Role Name', 2),
									_vm.vrules.maxLength(_vm.updateRole.role_name, 'Role Name', 100),
								],"placeholder":"Role Name"},model:{value:(_vm.updateRole.role_name),callback:function ($$v) {_vm.$set(_vm.updateRole, "role_name", $$v)},expression:"updateRole.role_name"}})],1)],1)],1),(_vm.getValue(_vm.getProfile, 'is_master') == 1)?_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"10","lg":"6"}},[_c('v-container',{staticClass:"py-0 pt-3",attrs:{"fluid":""}},[_c('v-autocomplete',{attrs:{"items":_vm.users_type_list,"item-text":"title","item-value":"value","hide-details":"","outlined":"","placeholder":"User Type"},model:{value:(_vm.updateRole.users_type),callback:function ($$v) {_vm.$set(_vm.updateRole, "users_type", $$v)},expression:"updateRole.users_type"}})],1)],1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"10","lg":"6"}},[_c('v-container',{staticClass:"py-0 pt-0",attrs:{"fluid":""}},[_c('TextAreaInput',{attrs:{"hide-details":"","word-limit":200,"disabled":_vm.pageLoading,"loading":_vm.pageLoading,"id":"description","placeholder":"Description","rules":[
									_vm.vrules.minLength(_vm.updateRole.description, 'Description', 1),
									_vm.vrules.maxLength(_vm.updateRole.description, 'Description', 1024),
								]},model:{value:(_vm.updateRole.description),callback:function ($$v) {_vm.$set(_vm.updateRole, "description", $$v)},expression:"updateRole.description"}})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"10"}},[_c('v-container',{staticClass:"py-0 pt-2",attrs:{"fluid":""}},[_c('Permissions',{attrs:{"permissions":_vm.permissions,"updatePermissionsList":_vm.formPermissionsList},on:{"updatePermissionData":_vm.updatePermissionData}})],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }